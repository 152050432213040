.top {
    width:25rem;
    height: 13.333333rem;
}
.top img {
    width:25rem;
    height: 13.333333rem;
}

/* 新闻带图展示 */
.newspart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
}
.newsone {
    width: 23rem;
    height: 28rem;
    margin: auto;
    margin-top: 2.533333rem;
}
.newsimg {
    height: 15.866667rem;
    overflow: hidden;
}
.newsimg {
    width: 23rem;
    height: 15.866667rem;
}
.newsone h5 {
    margin-top: 1.333333rem;
    margin-bottom: .666667rem;
    color: #CC0E17;
    box-sizing: border-box;
    font-size: 1.066667rem;
    font-weight: bold;
}
.newsone p{
    font-size: .933333rem;
    color: #878787;
    box-sizing: border-box;
    height: 3.333333rem;
    margin-bottom: .666667rem;
    text-overflow:ellipsis;
}
.newsone .more {
    width: 5.333333rem;
    height: 2rem;
    font-size: .933333rem;
    color: white;
    line-height: 2rem;
    background:#CC0E17;
    border-color: transparent;
    text-align: center;
}
.newsone .datee {
    color: #CC0E17;
    font-size: .8rem;
    text-align: right;
    margin-top: .4rem;
    font-weight:400;
    color:rgba(204,14,23,1);
}
.newsone .line {
    width: 23rem;
    height: .133333rem;
    background-color: #CC0E17;
    margin-top: .8rem;
}


/* 新闻要点展示 */
.newstwo:nth-of-type(1) {
    margin-top: 2.266667rem;
}
.newstwo {
    width: 23.333333rem;
    height: auto;
    line-height: 2rem;
    padding-left: 1.333333rem;
    box-sizing: border-box;
    white-space:nowrap;
    overflow: hidden;
}

.newstwo>li{
    /* width: 330px;
    white-space:nowrap;
    overflow: hidden;
    text-overflow:ellipsis; */
    list-style: disc;
}
.newstwo li a {
    color: #3A3A3A;
    font-size: .933333rem;
}
