.center {
    margin: 0 auto;
}

.clear {
    clear: both;
}

.topnav {
    width: 100%;
    min-width: 1300px;
    height: 80px;
    background-color: #CC0E17;
    line-height: 80px;
    box-sizing: border-box;
    float: left;
    white-space: nowrap;
    position: fixed;
    z-index: 999;
}

.space {
    height: 80px;
}

.left {
    margin-left: 9.396%;
}

.right {
    float: right;
    margin: 0 auto;
    margin-right: 30px;
    min-width:900px;
}

.right li {
    float: left;
    padding: 0 3.177%;
    box-sizing: border-box;
    font-size: 16px;
    color: white;
    font-weight: 700;
}

/* .topnav .right {
    color: white;
} */

.topnav .right li:not(.search):hover {
    background-color: #A30008;
}
.topnav .right li:not(.search):hover a{
   color: white;
} 



.navinfo {
    width: 100%;
    height: 170px;
    background-color: white;
    box-sizing: border-box;
    float: left;
    white-space: nowrap;
    position: fixed;
    z-index: 1;
    color: #C40000;
    font-size: 16px;
    padding-top: 24px;
    box-sizing: border-box;
}

.navinfo .left {
    display: flex;
    font-weight: bold;

}

.navinfo img {
    height: 35px;
    margin-right: 14px;
    margin-top: 10px;

}
.navinfo .left li:nth-of-type(2) {
    font-size: 30px;
}
.navinfo .right a {
    font-weight: normal;
}
.navinfo .right a:hover{
    color: #C40000;
    font-weight: bold;
}
.navinfo .right>li {
    width: 122px;
}

.navinfo a {
    color: #C40000;

}

.navinfo .ul li {
    margin-top: 4px;
}


/* 搜索界面 */
.search_page {
    width: 100%;
    min-width: 1300px;
    height: 80px;
    background-color: #CC0E17;
    line-height: 80px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    z-index: 999;
    display: flex;
    display:none;
}
.search_center {
    width: 33%;
    height: 80px;
    margin: auto;
    padding: 20px 15px;
    box-sizing: border-box;
    line-height: 42px;
}
.search_center img {
    width: 22px;
    height: 22px;
}
.search_center input {
    height: 40px;
    width: 500px;
    padding: 0 10px;
    box-sizing: border-box;
    display: inline-block;
    margin: 0  15px;
}

.search_center input {
    caret-color: white;
    background-color:transparent;
    border: 0px;
    outline:none;
    color: white;
}


.search_center input::-webkit-input-placeholder {
    color: white;
    text-align: center;
}
