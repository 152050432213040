.footer_top {
    width: 15.4rem;
    /* height: 17.6rem; */
    /* background-color: yellow; */
}
.footer {
    background-color: #1D1D1D;
    margin-top: 3.333333rem;
    padding-left: .666667rem;
    box-sizing: border-box;
}

.footer_top ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* margin-top: 30px; */
    padding-top: 1.6rem;
  

}
.footer_top ul li {
    margin-top: 1.333333rem;
}
.footer_top ul a {
    font-size: 1.066667rem;
    color: #ffffff;
}

.zhaoShang,.address {
    color: #ffffff;
    font-size: 1rem;
    margin-top: 1.6rem;
}
.zhaoShang,.address  a {
    color: white;
    margin-left: .5rem!important;
}
.zhaoShang span {
    display: block;
}
.zhaoShang span,
.address span {
    /* margin-left: 1.5rem; */
}
.zhaoShang span:nth-of-type(1),
.address span:nth-of-type(1) {
    margin-left: 5.733333rem;
    font-size: 1.1rem;
}
.zhaoShang img:nth-of-type(2),
.address img:nth-of-type(2) {
    margin-left: 13rem;
}



.contact {
    width: 50%;
    height: 100%;
    padding-bottom: 1.333333rem;
    color: white;
    font-size: 1.066667rem;
    margin-top: 2.333333rem;

}

.contact input {
    width: 20.666667rem;
    height: 2.666667rem;
    border: 1px solid rgba(205, 205, 205, 1);
    padding: .333333rem;
    box-sizing: border-box;
    background-color: transparent;
    color: #FFFFFF;
}
.contact input:nth-of-type(2) {
    margin-top: 1.2rem;
}

.contact input:nth-of-type(1) {
    margin-right: 5.733333rem;
}

.contact textarea {
    width: 20.666667rem;
    height: 9.333333rem;
    border: 1px solid rgba(205, 205, 205, 1);
    margin-top: 1.333333rem;
    padding: .333333rem;
    box-sizing: border-box;
    background-color: transparent;
    color: #FFFFFF;
}

.contact button {
    width: 5.333333rem;
    height: 2.666667rem;
    font-size: 1.066667rem;
    margin-top: 1.333333rem;
    background:rgba(255,255,255,1);
    color: black;
}
.contact_img {
   width: 100%;
   height: 14.666667rem;
}
.contact_img img {
    width: 9.466667rem;
    height: 10.666667rem;
    float: right;
    margin-right: .666667rem;
}

.myaddress {
    margin-left: .5rem!important;
}
.toppointTwo {
    margin-top: .5rem;
}
.phoneNum {
    display: block!important;
    margin-left: 72px!important;
}