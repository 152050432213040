.menuTop img {
    width: 25rem;
    height:13.333333rem;
}
.mainmenu {
    margin-top: 2.5rem;
}
.menuNav {
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 0;

}
.menuNav li {
    width: 5.066667rem;
    height: 2.133333rem;
    border: 1px solid #E6E6E6;
    color: #797979;
    font-size: 1.066667rem;
    text-align: center;
    line-height: 2.133333rem;
    margin-right: 1.1rem;
}
.menuNav li:nth-of-type(4n+4) {
    margin-right: 0rem;
}
.menuInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.menuOne {
    width: 11.6rem;
    height: 14.2rem;
    overflow: hidden;
    position: relative;
    margin-top: 2rem;
}
.menuImg img {
    width: 11.6rem;
    height: 11.6rem;
}

.menuinfoss h5 {
    width: 11.6rem;
    height: 2.533333rem;
    border: 1px solid #E6E6E6;
    color: #CC0E17;
    font-weight: bold;
    text-align: center;
    font-size: 1.066667rem;
    line-height: 2.533333rem;
}

/* 弹出层 */
.pro_detail {
    width: 100%;
    height: 100%;
    background-color: rgba(12, 12, 12,.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 88;
    text-align: right;
    /* display:none; */
}
.pro_detail .menu_close {
  font-size: 26px;
  margin-right: 10px;
  line-height: 1;
}
.pro_detail .menuinfoss {
    margin: auto;
    background-color: #E6E6E6;
    width: 21.333333rem;
    height: 25.533333rem;
}
.pro_detail .menuImg {
    display: flex;
}
.pro_detail .menuImg img{
    width: 19.6rem;
    height: 19.6rem;
    margin: auto;
}
.pro_detail h5 {
    width: 100%;
    text-align: center;
    line-height: 3.533333rem;
}


