.center {
    width: 1200px;
    height: auto;
    display: flex;
    margin: 0 auto;
}

.bottomnav {
    width: 100%;
    height: 548px;
    background-color: #1D1D1D;
    padding: 47px 0px 50px 0px;
    box-sizing: border-box;
    font-size: 16px;
    color: #FFFFFF;
}

.left {
    width: 30%;
    height: 100%;
}

.left input {
    width: 160px;
    height: 47px;
    border: 1px solid rgba(205, 205, 205, 1);
    padding: 5px;
    box-sizing: border-box;
    background-color: transparent;
    color: #FFFFFF;
}

.left input:nth-of-type(1) {
    margin-right: 36px;
}

.left textarea {
    width: 355px;
    height: 108px;
    border: 1px solid rgba(205, 205, 205, 1);
    margin-top: 20px;
    padding: 5px;
    box-sizing: border-box;
    background-color: transparent;
    color: #FFFFFF;
}

.left button {
    width: 112px;
    height: 39px;
    font-size: 16px;
    margin-top: 20px;
    background:rgba(255,255,255,1);
    color: black;
}

.right {
    width: 70%;
    height: 100%;
    color: #FFFFFF;
    padding-left: 80px;
    padding-top: 16px;
    box-sizing: border-box;
}
.right a {
    color: #FFFFFF; 
}
.right a:hover {
    color: #CC0E17;
}
.navinfo {
    display: flex;
}
.navinfo li {
    margin-right: 24px;
}

.shopinfo {
    display: flex;
    margin-top: 30px;
    width: 100%;
}
.shopinfo div {
    margin-right: 30px;
}
.shopinfo span {
    display: inline-block;
    margin-left: 20px;
    margin-top: 5px;
}

.shopinfo span:nth-of-type(1){
    margin-left: 80px;
}

.shopinfo img:nth-of-type(2) {
    margin-left: 224px;
}

.wechart {
    width: 176px;
    height: 196px;
    margin-left: 55px;
    margin-top: -33px;
}

.botttt {
    display: flex;
}
.botttt .wechart {
    display: inline-block;
}
.shopinfo .toppoints{
    margin-left: 240px!important;
    margin-top: 0!important;
    
}
.shopinfotwo {
    margin-right: 0!important;
}
.comadd {
    margin-left: 102px!important;
    margin-bottom: 14px!important;
}
.comaddDetail {
    margin-top: 30rpx!important;
    margin-bottom: 5px!important;
}
.phoneNum {
    display: inline-block;
    margin-left: 20px!important;
}