.top {
    width: 100%;
    height: 600px;
    background: url("../img/shopshowbg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 120px;
}


.center {
    width: 1200px;
    height: auto;
    /* background-color: yellow; */
    margin: 0 auto;
}


/* 组件 */

.shopone {
    height: 450px;
    overflow: hidden;
    margin-bottom: 80px;
    display: flex;
    color: white;
}

.shopone .left {
    width: 800px;
    overflow: hidden;
}

.shopone .left img {
    width: 800px;
    height: 450px;
}

.shopone .left:hover .imgbig {
    transition: 1s;
    transform: scale(1.1)
}

.shopone .right {
    width: 400px;
    height: auto;
    background-color: #c2040d;
    text-align: center;
    line-height: 60px;
}

.shopone .right .line {
    height: 2px;
    width: 30px;
    transition: .5s;
    background-color: white;
    margin: 0 auto;
}

.shopone:hover .line {
    width: 230px;
}

.shopone .right h3 {
    color: white;
    font-size: 30px;
    margin-top: 170px;
}

.shopone .right .tel {
    font-size: 24px;
}

.shopone .right .address {
    font-size: 16px;
    padding: 0 14px;
}