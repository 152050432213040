.mapp {
    height: 200px;
    width: 100%;
    background-color: pink;
    margin-bottom: 40px;
}
.content {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.contentone {
    color: #9B9B9B;
    font-size: 18px;
}
.contenttwo {
    color: #313131;
    font-size: 20px;
}
.imgbottom {
    margin-top: 30px;
}

.bot_img img{
    width: 23.8rem;
    height: 12rem;
}