
.about_us {
    background-color: #F9F8F3;
}

/* 顶部图片 */
.top {
    width: 100%;
    height: 13.333333rem;
    overflow: hidden;
}

.top img {
    width: 25rem;
    height: 13.333333rem;
}



/* 公司简介 */
.compony {
    margin-top: 3.4rem;
    height: auto;
    display: flex;
    flex-direction: column;

}

.compony .left img {
    width: 18.666667rem;
    height: 13.333333rem;
}

.compony .right {
    box-sizing: border-box;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.compony .right span {
    width: 20rem;
    height: 1px;
    display: inline-block;
    border-bottom: 1px solid #C20000;
    /* position: absolute;
    top: 4.133333rem; */
    margin-bottom: 1rem;
}

/* .compony .right h2:nth-of-type(1) {
    color: #92783B;
    font-size: 2.6rem;
    opacity: 0.1;


} */

/* .compony .right h2:nth-of-type(2) {
    font-size: 1.4rem;
    color: #C20000;
    z-index: 999;
    position: absolute;
    top: .5rem;
    font-weight: bold;
} */

.compony .right p {
    font-size: 1rem;
    color: #919191;
}

.compony .right .plus {
    background-color: #CC0E17;
    font-size: 1.3rem;
    text-align: center;
    position: absolute;
    bottom: -2.666667rem;
    left: 17.333333rem;
    color: white;
    width: 5.2rem;
    height: 2rem;
}

.componyprofile {
    font-size: 2.6rem;
    font-weight: 500;
    color: #92783B;
    opacity: 0.1;
}



/* 品牌故事 */
.brandstory {
    /* height: 1000px; */
    margin-top: 2rem;
}

.title {
    height: 4rem;
    text-align: center;
    position: relative;
}

/* .title h2:nth-of-type(1) {
    font-size: 2.6rem;
    color: #92783B;
    opacity: 0.1;
} */

/* .title h2:nth-of-type(2) {
    color: #C20000;
    font-size: 1.4rem;
    font-weight: bold;
    position: absolute;
    top: .2rem;
    left: 9rem;
} */
h2 {
    color: #C20000;
    font-size: 1.4rem;
    font-weight: bold;
}

.storyshow .storyone {
    width: 13.333333rem;
    height: 11.666667rem;
    /* background-color: #CC0E17; */
    color: white;
    font-size: 1.333333rem;
    text-align: center;
    line-height: 11.666667rem;
    margin: auto;
    margin-top: 1rem;
}

.storyshow .storyone img {
    width: 13.333333rem;
    height: 11.666667rem;
}

.storyshow p {
    width: 13.333333rem;
    height: 11.666667rem;
    background-color: white;
    color: #8A8A8A;
    font-size: 1.066667rem;
    text-align: center;
    margin: auto;
    padding: 1.333333rem;
}


/* 品牌荣誉 */
.brandhonor {
    margin-top: 3.333333rem;
}

.honordetail {
    display: flex;
}

.honordetail .left img {
    width: 7.8rem;
    height: 13.8rem;
}

.honordetail .right {
    width: 15.333333rem;
    height: 13.8rem;
    background-color: #C20000;
}

.swiperbnt {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l4.2%2C4.2L8.4%2C22l17.8%2C17.8L22%2C44L0%2C22z'%20fill%3D'%23c60811'%2F%3E%3C%2Fsvg%3E") !important;
}

.swiperbntN {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L5%2C44l-4.2-4.2L18.6%2C22L0.8%2C4.2L5%2C0z'%20fill%3D'%23c60811'%2F%3E%3C%2Fsvg%3E") !important;

}