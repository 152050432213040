
.news_detail {
    margin-top: 1.5rem;
    height: auto;
    
}

.news_detail h2 {
    text-align: center;
    color: #1A1A1A;
    font-weight: 500;
    font-size: 1.2rem;
}

.news_detail .news_date {
    text-align: center;
    color: #999999;
    font-size: .933333rem;
}

.news_detail .news_info {
    /* text-align:justify;
    text-justify:inter-ideograph; */
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.news_detail .news_info img {
    max-width: 23rem;
}
.news_detail .news_info .news_img {
    width: 23rem;
    /* height: 9.6rem; */
    /* overflow: hidden; */
    /* text-align: center; */
    margin: auto;
}

.news_detail .news_info p {
    /* padding: 1.333333rem .666667rem; */
    /* text-align: center; */
}


.news_advise {
    margin-top: 2.2rem;
    /* margin-bottom: 1.666667rem; */
}



.news_advise span {
    font-size: 1.066667rem;
    color: #1A1A1A;
    display: inline-block;
    margin-left: 9.333333rem;
}



.news_advise_detail {
    margin-top: 1.8rem;
    display: flex;
    flex-direction: column;
}

.news_advise_one {
    width: 23rem;
    height: 15.333333rem;
    margin: auto;
}

.news_advise_one:nth-of-type(4) {
    margin-right: 0;
}

.news_advise_img {
    height: 9.466667rem;
    overflow: hidden;
}


.news_advise_one h4 a {
    color: #1A1A1A;
    font-size: 1.066667rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}

.news_advise_one p a {
    font-size: .933333rem;
    color: grey;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}
.news_advise_one h4 a:hover {
    color: #CC0E17;
}
.news_advise_one p a:hover {
    color: #CC0E17;
}