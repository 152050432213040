/* 顶部菜单栏 */
.topnav {

    width: 100%;
    height: 2.933333rem;
    line-height: 2.8rem;
    background-color: #CC0E17;
    position: fixed;
    top: 0;
    z-index: 88;
}

.nav_menu {
    display: inline-block;
    width: 1rem;
    height: 1rem;
}

.logo {
    margin-left: 9.333333rem;
    width: 2.666667rem;
    height: 1.733333rem;
}

.space_topnav {
    height: 2.933333rem;
}


/* 侧边导航栏 */

.side_nav {
    width: 14.666667rem;
    height: 43.133333rem;
    /* background-color: aquamarine; */
    z-index: 8888;
    position: fixed;
    top: 0;
    background-color: white;
    display: none;
}

.side_nav li {
    text-align: center;
    height: 2.933333rem;
    line-height: 2.933333rem;
}

.side_nav li:nth-of-type(1) {
    background-color: #303030;

}

.side_nav li:nth-of-type(1) a {
    color: white;
}

.side_nav a {
    color: #303030;
    font-size: 1rem;
    font-weight: bold;
}
.side_nav a:hover {
    color: #CC0E17;
}

.close {
    position: absolute;
    top: .533333rem;
    left: 12.933333rem;

}

.close img {
    width: .8rem;
    height: .933333rem;
}