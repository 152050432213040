.top {
    width: 25rem;
    height: 13.333333rem;
    margin-bottom: .8rem;
}
.top img {
    width: 25rem;
    height: 13.333333rem;
}

/* 组件 */

.shopone {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    color: white;
}
.shopone .left {
    width: 22.533333rem;
    height: 13.533333rem;
    overflow: hidden;
    margin: auto;
}
.shopone .left img {
    width: 22.533333rem;
    height: 13.533333rem;
}

.shopone .right {
    width: 22.533333rem;
    height: auto;
    background-color: #c2040d;
    text-align: center;
    margin: auto;
}
.shopone .right .line {
    height: .133333rem;
    width: 2rem;
    transition: .5s;
    background-color: white;
    margin: 0 auto;
    margin-bottom: 1.5rem;
}
.shopone:hover .line {
    width: 15.333333rem;
    margin-bottom: .9rem;
}

.shopone .right h3 {
    color: white;
    font-size: 1.2rem;
    margin-top: .9rem;
}

.shopone .right .tel {
    font-size: 1.066667rem;
    margin: .4rem;
}
.shopone .right .address {
    font-size: 1.066667rem;
    margin-bottom: .9rem;
    padding: 0 .4rem;
} 