.top {
    width: 25rem;
    height: 13.333333rem;
    margin-bottom: 2rem;
}
.top img {
    width: 25rem;
    height: 13.333333rem;
}

/* 品牌优势 */
.brandadv {
    height: auto;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}
.titletop {
    margin-bottom: -1.4rem;
    z-index: 8;
}
.titletopOne {
    margin-left: -0.8rem;
}
.titletop img {
    width: 18rem;
    height: 3.333333rem;
}
.brandadvbot {
    display: flex;
    flex-direction: column;
}
.brandadvbot .brandadvleft{
    width: 12.533333rem;
    height: 23.133333rem;
}
.brandadvbot .brandadvleft img {
    width: 12.533333rem;
    height: 23.133333rem;
}
.brandadvbot .brandadvright {
    width: 22.666667rem;
    height: auto;
    padding: .666667rem;
    box-sizing: border-box;
    margin-top: 1.4rem;
}
.brandadvrightdetail {
    display: flex;
    margin-bottom: 1.333333rem;
}
.brandadvrightdetail img {
    width: 1.3rem;
    height: 1.3rem;
}
.advright  {
    width: 22.666667rem;
    text-align: left;
    margin-left: .666667rem;
}
.advtop {
    color: #CC0E17;
    font-size: 1.066667rem;
    font-weight: bold;
}
.advbot{
    color: #949494;
    font-size: .933333rem;
}

/* 加盟流程 */

.joinprocess {
    margin-top: 1.733333rem;
    margin-bottom: 3.333333rem;
    width: 100%;
    height: auto;
    text-align: center;
}
.joinprocess .titletop  {
    margin-bottom: 2rem;
    text-align: left;
}
.joinshow {
    width: 100%;
    height: 31.333333rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}
.joinshowone {
    width: 11.333333rem;
    height: 9.333333rem;  
    padding: 1rem .666667rem;
    box-sizing: border-box;
}

.joinone {
    background:url("../img/joinone.png");
    background-size: 11.333333rem 9.333333rem;
}
.jointwo {
    background:url("../img/jointwo.png");
    background-size: 11.333333rem 9.333333rem;
}
.jointhree {
    background:url("../img/jointhree.png");
    background-size: 11.333333rem 9.333333rem;
}
.joinfour {
    background:url("../img/joinfour.png");
    background-size: 11.333333rem 9.333333rem;
}
.joinfive {
    background:url("../img/joinfive.png");
    background-size: 11.333333rem 9.333333rem;
}
.joinsix {
    background:url("../img/joinsix.png");
    background-size: 11.333333rem 9.333333rem;
}

.joinshowpic {
    margin-bottom: .466667rem;
}
.joinshowpic img {
    width: 1.666667rem;
    height: 1.4rem;
}
.joinshowtile {
    font-size: .933333rem;
    font-weight:500;
    color:rgba(255,255,255,1);
    margin-bottom: .466667rem;
    font-weight: bold;
}

.joinshowone .line {
    width: 2rem;
    height: .133333rem;
    background-color: white;
    margin: 0 auto;
    margin-bottom: .466667rem;
    transition: 0.5s;
    -webkit-transition:0.5s; /* Safari */
}

.joinshowone p {
    font-size: .8rem;
    color: white;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}




/* 加盟支持 */
.support {
    margin-bottom:4rem;
}
.support .titletop {
    margin-bottom: 2rem;
}

/* .perbox {
    overflow: hidden;
    
} */
.perbox {
    width: 11.333333rem;
    height: 11.333333rem;
}
.perbox img {
    width: 11.333333rem;
    height: 11.333333rem;
}

/* 底部图片 */

.coobot {
    width: 25rem;
    height: 14.8rem;
}

.coobot img {
    width: 25rem;
    height: 14.8rem;
}

/* 调整 */
.jointwoo {
    color: #CC0E17;
    /* font-weight: bold; */
}
.lineoo {
    background-color:#CC0E17!important;
}
.content {
    color: #999!important; 
}