@font-face {
  font-family: PingFang-ExtraLight;
  src: url("./pingfang/PingFang ExtraLight_0.ttf");
}

@font-face {
  font-family: PingFang-Bold;
  src: url("./pingfang/PingFang Bold_0.ttf");
}

@font-face {
  font-family: PingFang-Heavy;
  src: url("./pingfang/PingFang Heavy_0.ttf");
}

@font-face {
  font-family: PingFang-Light;
  src: url("./pingfang/PingFang Light_0.ttf");
}

@font-face {
  font-family: PingFang-Medium;
  src: url("./pingfang/PingFang Medium_0.ttf");
}

@font-face {
  font-family: PingFang-Medium;
  src: url("./pingfang/PingFang Regular_0.ttf");
}


body { 
  background-color: #F6F5F0!important;
  text-align:justify; text-justify:inter-ideograph

}
@media screen and (min-width:1200px) {
  body {
    font-family: PingFang SC !important;
    margin: 0;
    width: 100%;
    min-width: 1200px;
    /* font-family: Microsoft YaHei, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: url("./views/img/bgctwo.png");
    background-repeat: repeat;
    background-size: cover; */

  }

  .center {
    width: 1200px;
    height: auto;
    /* background-color: yellow; */
    margin: 0 auto;
  }

  ul li {
    list-style: none;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  html {
    width: 100%;
    min-width: 1200px;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  a {
    text-decoration: none;
    color: black;
  }

  /* 首页第一个轮播 */
  .ant-carousel {
    /* text-align: center; */
    /* height: 970px; */
    /* line-height: 0px; */
    /* background: #364d79; */
    /* overflow: hidden; */
  }

  .ant-carousel .slick-slide h3 {
    color: #fff;
  }

  .ant-carousel .slick-dots-bottom {
    bottom: 114px !important;
  }

  .ant-carousel .slick-dots li button {
    width: 12px !important;
    height: 12px !important;
    border-radius: 6px !important;
  }



  /* 首页第二个轮播 */
  .swiper-pagination-bullet {
    opacity: .5 !important;
    background-color: white !important;
  }

  .swiper-pagination-bullet-active {
    opacity: 1 !important;
    background-color: white !important;
  }

  .swiper-pagination-bullet {
    width: 12px !important;
    height: 12px !important;
  }


  /* 全局样式渐入 */
  /* .hidden {
    display: none;
  }
  .show {
    display: inline-block;
    position: absolute;
    left:-30em;
    -webkit-animation: slide-in 1s forwards;
    animation: slide-in 1s forwards;
  }
  @-webkit-keyframes slide-in {
    100% { left: 0 }
  }
  @keyframes slide-in {
    100% { left:0  }
  } */


  .menuOne {
    width: 250px;
    height: 312px;
    margin-bottom: 50px;
    position: relative;
    overflow: hidden;
  }

}





@media screen and (min-width: 320px) and (max-width: 359px) {

  html {

    font-size: 12.8px;

  }

}

@media screen and (min-width: 360px) and (max-width: 374px) {

  html {

    font-size: 14.4px;

  }

}

@media screen and (min-width: 375px) and (max-width: 385px) {

  html {

    font-size: 15px;

  }

}

@media screen and (min-width: 386px) and (max-width: 392px) {

  html {

    font-size: 15.44px;

  }

}

@media screen and (min-width: 393px) and (max-width: 400px) {

  html {

    font-size: 16px;

  }

}

@media screen and (min-width: 401px) and (max-width: 414px) {

  html {

    font-size: 16.48px;

  }

}

@media screen and (min-width: 750px) and (max-width: 799px) {

  html {

    font-size: 30.72px;

  }

}

@media screen and (max-width: 799px) {
  body {
    margin: 0;
    width: 100%;
    /* font-family: Microsoft YaHei, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif; */
    font-family: PingFang SC !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: url("./components/imgM/bg.png") */
  }

  .center {
    /* width: 23.5rem; */
    padding: 0 .666667rem;
    box-sizing: border-box;
    height: auto;
    /* background-color: yellow; */
    /* margin: 0 auto; */
    overflow-x: hidden;

  }

  ul li {
    list-style: none;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  html {
    width: 100%;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  a {
    text-decoration: none;
    color: black;
  }

  /* 首页第一个轮播 */
  .swiper-pagination-bullet {
    opacity: .5 !important;
    background-color: white !important;
  }

  .swiper-pagination-bullet-active {
    opacity: 1 !important;
    background-color: white !important;
  }

  /* 合作加盟轮播 */
  .my-bullet {
    background: blue !important;
  }

  .my-bullet-active {
    background: #CC0E17 !important;
    opacity: 1 !important;
  }

  .swiper-scrollbar {
    background: transparent !important;
  }

}




