.lunbo_top {
    height: 13.333333rem;
}
.lunbo_top .img_round {
    width: 25rem;
    overflow: hidden;
    height: 100%;
}

.lunbo_top img {
    width: 100%;
    height: 13.333333rem;
}


/* 关于我们 */
.aboutus {
    margin-top: 2.5rem;
}

.aboutus_title {
    width: 7rem;
    height: 2rem;
}
.mycenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.aboutus .imgtitle {
    margin: auto;
    margin-bottom: 1rem;
}
.aboutus .contents {
    text-align: center;
    margin-bottom: 1rem;
    color: #222222;
    font-size: .92rem;
}

.more {
    margin: auto; 
    margin-bottom: 1rem;
    border: 1px solid #545454;
    width: 5.266667rem;
    height: 2.2rem;
    line-height: 1.866667rem;
    text-align: center;
}
.more a{
    color: #222222;
    font-size: .92rem;
}

.aboutus .imgtwo {
    width: 100%;
    overflow: hidden;
    display: flex;
    margin-top: 0.3rem;
    height: 10.733333rem;
}
.aboutus .img_aboutus {
    width: 100%;
    height: 100%;
    margin: auto;
    object-fit: fill;
}


/* 菜品展示轮播 */
.menushow {
    width: 100%;
    height: 23.933333rem;
    background: url("../img/menubgc.png");
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
}

.menushow .imgtitle {
    display: inline-block;
    margin: 1.733333rem auto;
    width: 7rem;
    height: 2rem;
}

.mycenter_two {
    width: 20rem;
    height: 16.55rem;
    background-color: #CC0E17;
    overflow: hidden;
}

.menu_item {
    display: flex;
    flex-direction: column;
}

.menu_img {
    height: 12.666667rem;
    overflow: hidden;
}
.menu_imgone {
    height: 12.666667rem;
    width: 20rem;
}
.menu_item h5 {
    margin: auto;
    font-size: .933333rem;
    color: white;
}
.menu_item h5:nth-of-type(1) {
    margin-top: .533333rem!important;
}
.menu_item h5:nth-of-type(2) {
    margin-bottom: .533333rem!important;
}


/* 新闻资讯 */
.news {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.news .imgtitle{
    width: 7rem;
    height: 2rem;
}
.newsone{
    display: flex;
    flex-direction: column;
}
.newsone .newsimg {
    margin: .933333rem auto;
    width: 23rem;
    height: 10rem;
    overflow: hidden;
}
.newsimgone {
    width: 23rem;
    height: 10rem;
}
.newsone h4 {
    color: #121212;
    font-size: 1.066667rem
}
.newsone p {
    color: #6A6A6A;
    font-size: .92rem;
    margin: .3rem 0;
}
.newsone .more {
    margin-top: .933333rem;
    border-radius: .066667rem;
    width: 5rem;
    height: 2rem;
    background-color: #CC0E17;
    color: white;
    border: 1px solid transparent;
    font-size: 1rem;
}
.newsone .more a {
    color: white;
    font-size: 1rem;
}


/* 门店展示 */
.shopshow {
    width: 100%;
    height: 28rem;
    background:url("../img/shop_showbg.png");
    margin-top: 2.1rem;
    text-align: center;
    /* display: flex; */
    /* flex-direction: column; */
}
.shopshow .imgtitle {
    width: 7rem;
    height: 2rem;
    margin-top: 1.733333rem;
    margin:2.666667rem 0 1.333333rem;
}
.shop_pic {
    margin-bottom: .066667rem;
}
.shop_pic img {
    width:23.5rem;
    height: 17.066667rem;
    margin-left: 1.2rem;
}
.shopshow .more {
    border: 1px solid #545454;
    width: 5.266667rem;
    height: 2.2rem;
    line-height: 1.866667rem;
    text-align: center;
}
.shopshow .more a{
    color: #545454;
    font-size: .92rem;
}


/* 合作加盟 */

.operation {
    margin-top: 3rem;
    text-align: center;
   
}

.operation .coo_title {
    margin-bottom: 1rem;
    width: 7rem;
    height: 2rem;
}
.operation p {
    font-size: .92rem;
    color: #222222;
    margin-bottom: 1.2rem;
}
.operation .more {
    width: 10.133333rem;
    height: 2.8rem;
    line-height: 2.8rem;
    background-color: #CC0E17;
    border-color: transparent;
    margin: 2rem auto;

}
.operation .more a {
    color: white;
    font-size: 1rem;
}
.fivehot img {
    width: 7.333333rem;
    height: 16.266667rem;
}